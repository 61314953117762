import {AfterViewInit, ChangeDetectorRef, Directive, ElementRef, NgZone, OnDestroy, OnInit, Renderer2, ViewContainerRef} from '@angular/core';
import {_} from '@wspsoft/underscore';
import {DeferredLoader} from 'primeng/defer';
import {Subscription} from 'rxjs';

import {StructureService} from '../service/structure.service';

@Directive({
  selector: '[ui-defer]'
})
export class DeferDirective extends DeferredLoader implements OnInit, AfterViewInit, OnDestroy {
  private layoutSubscription: Subscription;
  private scrollSubscription: Subscription;

  public constructor(private zone: NgZone, el: ElementRef, renderer: Renderer2, viewContainer: ViewContainerRef,
                     private structureService: StructureService, cdr: ChangeDetectorRef) {
    super(el, renderer, viewContainer, cdr);
  }

  // overwrite default things (not working for our use case)
  // eslint-disable-next-line
  public ngAfterViewInit(): void {
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.layoutSubscription?.unsubscribe();
    this.scrollSubscription?.unsubscribe();
  }

  public ngOnInit(): void {
    const generatorOrNext = _.throttle(this.onInit, 750, true).bind(this);
    this.layoutSubscription = this.zone.onStable.subscribe(() => {
      this.zone.runOutsideAngular(generatorOrNext);
    });
  }

  private listen(): void {
    if (this.shouldLoad()) {
      this.load();
    } else if (!this.scrollSubscription) {
      const generatorOrNext = _.throttle(this.onChange, 500).bind(this);
      this.layoutSubscription = this.structureService.layoutChange.subscribe(generatorOrNext);
      this.scrollSubscription = this.structureService.onScroll.subscribe(generatorOrNext);
    }
  }

  private onChange(): void {
    if (this.shouldLoad()) {
      this.zone.run(() => {
        this.load();
        this.layoutSubscription.unsubscribe();
        this.scrollSubscription.unsubscribe();
      });
    }
  }

  private onInit(): void {
    this.layoutSubscription.unsubscribe();
    this.zone.run(() => this.listen());
  }
}
