<ui-input-wrapper [(editMode)]="editMode" [disable]="disable" [filled]="!!value" [helpMessage]="helpMessage" [label]="label" [linkify]="linkify"
                  [require]="require" [value]="value">
  <ng-container *ngIf="!multiple else chips" [ngTemplateOutlet]="inputText"></ng-container>
</ui-input-wrapper>
<ng-template #inputText>
  <div [ngClass]="{
  'one-input--readonly': disable
}" class="one-input">
    <span *ngIf="symbol" class="one-input__decorator">{{symbol}}</span>
    <span *ngIf="isI18n" class="one-input__decorator">{{currentLanguage}}</span>
    <input (keydown)="keydown($event)" [(ngModel)]="value" [autocapitalize]="autocapitalize" [disabled]="disable" [maxlength]="maxLength"
           [ngModelOptions]="{updateOn: updateOn}" [ngStyle]="styleData" [readonly]="keyInput" [required]="require" [type]="type" name="native" pInputText
           style="width:100%">
    <ng-container *ngIf="isI18n && !disable">
      <p-menu #languageOptions [model]="languageOptionButtons" [popup]="true" appendTo="body"></p-menu>
      <ui-button (clicked)="languageOptions.toggle($event.originalEvent)" [spinner]="false" [type]="['icon-only',
      'inline']" icon="fas fa-fw fa-ellipsis-v" label="{{'Input.TranslateInput' | translate}}"></ui-button>
    </ng-container>
    <ng-container *ngIf="keyInput && !disable">
      <ui-button (clicked)="this.value = null" [spinner]="false" [type]="['icon-only', 'inline']" icon="fas fa-times"
                 label="{{'Input.Reset' | translate}}"></ui-button>
    </ng-container>
  </div>
</ng-template>
<ng-template #chips>
  <p-chips [(ngModel)]="value" [addOnBlur]="true" [addOnTab]="true" [allowDuplicate]="false" [disabled]="disable" [ngModelOptions]="{updateOn: updateOn}"
           [required]="require" class="one-input {{disable ? 'one-input--readonly' : ''}}" name="native" separator=","></p-chips>
</ng-template>
