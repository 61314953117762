import {ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Host, Input, OnInit, Optional, Output, SkipSelf} from '@angular/core';
import {ControlContainer, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Utility} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {AutoComplete} from '../autocomplete';

@Component({
  selector: 'ui-autocomplete',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['../autocomplete.scss', './autocomplete-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AutocompleteInputComponent),
    multi: true
  }],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: (container: ControlContainer) => container,
    deps: [[new Optional(), new Host(), new SkipSelf(), ControlContainer]],
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteInputComponent extends AutoComplete<any> implements OnInit {
  @Input()
  public renderInputGroup: boolean = true;
  @Input()
  public field: string;
  @Input()
  public showIcons: boolean;
  @Output()
  public completeMethod: EventEmitter<any> = new EventEmitter();
  private originalSuggestions: any[];

  @Input()
  public get suggestions(): any {
    return super.suggestions;
  }

  public set suggestions(value: any[]) {
    // Remove the current values from the suggestion when multiple is true
    if (!_.isNullOrEmpty(this.value)) {
      for (const val of this.multiple ? this.value : [this.value]) {
        if (typeof val === 'object' && val[this.field] !== undefined) {
          value = _.filter(value, x => x[this.field] !== val[this.field]);
        } else {
          value = _.filter(value, x => x !== val);
        }
      }
      if (!this.multiple) {
        value.unshift(this.value);
      }
    }
    super.suggestions = value;
  }

  public onComplete($event: any): void {
    this.lastQuery = $event.query;
    this.completeMethod.emit($event);
  }

  public ngOnInit(): void {
    if (!this.completeMethod.observed) {
      this.originalSuggestions = this.suggestions;
      this.subscription = this.completeMethod.subscribe($event => {
        this.suggestions = this.originalSuggestions.filter(t => Utility.matches(t.label, $event.query));
        if ($event.originalEvent.cb) {
          $event.originalEvent.cb();
        }
      });
    }
  }
}
