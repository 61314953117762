import {DesignerEntity} from './designer.entity';
import {DisplayTransformation} from './display-transformation';
import {LayoutSectionToSectionField} from './layout-section-to-section-field';
import {SectionFieldToButton} from './section-field-to-button';

/**
 * configured input field
 */
export abstract class SectionField extends DesignerEntity {
  public multiple?: boolean;
  public updateWhileTyping?: boolean;
  public defaultOnHide?: boolean;
  public readonly?: string;
  public mandatory?: string;
  public rendered?: string;
  public helpMessages?: string;
  public entityId?: string;
  public title?: string;
  public sectionFieldToButtons?: SectionFieldToButton[];
  public layoutSectionToSectionFields?: LayoutSectionToSectionField[];
  public tourStepId?: string;
  public displayTransformId?: string;
  public dynamicSettings?: string;
  public dynamicTransform?: DisplayTransformation;

  // field ac
  public allowDotWalk?: boolean;

  // number
  public integerOnly?: boolean;
  public maxValue?: number;
  public minValue?: number;

  // picklist
  public filterScriptTarget?: string;

  // ac
  public groupBy?: string;
  public additionalAttributesDisplay?: string[];
  public additionalAttributesQuery?: string[];
  public filterScript?: string;

  // custom ac
  public values?: string[];
  public valueScript?: string;
  public convertScript?: string;
  public valueField?: string;
  public displayField?: string;
  public forceSelection?: boolean;

  // calendar
  public markedDates?: string;

  // choice option
  public displayType?: ChoiceOptionDisplayType | CheckboxDisplayType;
  public displayOptions?: ChoiceOptionDisplayOptions;

  // large text
  public editorMode?: LargeTextEditorMode;
  public language?: CodeEditorLanguages;
  public compact?: boolean;
  public codeEditorOptions?: CodeEditorOptions[];

  // input
  public linkify?: boolean;
  public keyInput?: boolean;
  public maxLength?: number;

  // gallery
  public aspectRatio?: AspectRatio;
  public maxWidth?: number;
  public maxHeight?: number;

  // file upload
  public fileType?: string;
  public publik?: boolean;
  public maxSize?: number;

  /// password
  public disableAutofill?: boolean;
  public strongRegex?: string;
  public showShowPassword?: boolean;

  // condition builder
  public queryReturnType?: boolean;
  public mode?: string[];
  public options?: ConditionBuilderOptions[] | DurationOptions[];
  public entityScript?: string;
  public recordClassScript?: string;
  public codeContextScript?: string;
  public allowCustomScripts?: boolean;
  public allowScriptedRule?: boolean;
  public scriptLanguage?: CodeEditorLanguages;

  // field ac
  public localized?: boolean;


  // date stuff
  public type?: DatePickerType;
  public endDate?: string;

  // qr
  public actionScript?: string;

  // low code
  public workflow?: boolean;

  // custom web component
  public webComponentId?: string;
  public properties?: Record<any, any>;

  // icon ac
  public iconOptions?: IconOptions[];
}

export enum IconOptions {
  animations = 'animations',
  flips = 'flips',
  rotations = 'rotations',
}

export enum ChoiceOptionDisplayOptions {
  SMALL = 'small', LARGE = 'large', MEDIUM = 'medium', ICON_ONLY = 'iconOnly'
}

export enum ChoiceOptionDisplayType {
  RADIO = 'RADIO', SWITCH = 'SWITCH'
}

export enum CheckboxDisplayType {
  CHECKBOX = 'CHECKBOX', SWITCH = 'SWITCH'
}

export enum ConditionBuilderOptions {
  SCRIPTED_VALUE = 'ScriptedValue', RECORD = 'Record', USER = 'User'
}

export enum DurationOptions {
  WEEKS = 'weeks', MONTHS = 'months', YEARS = 'years', DAYS = 'days', HOURS = 'hours', MINUTES = 'minutes', SECONDS = 'seconds'
}

export enum AspectRatio {
  shitInShitOut = 'shitInShitOut', circle = 'circle', square = 'square'
}

export enum DatePickerType {
  SINGLE = 'single', RANGE = 'range', MULTIPLE = 'multiple'
}


export enum LargeTextEditorMode {
  CODE_EDITOR = 'CODE_EDITOR', HTML = 'HTML', PLAIN = 'PLAIN', MARKDOWN = 'MARKDOWN'
}

export enum CodeEditorLanguages {
  JAVASCRIPT = 'javascript', TYPESCRIPT = 'typescript', CSS = 'css', HTML = 'html', JSON = 'json', LOG = 'log'
}

export enum CodeEditorOptions {
  SERVER_SIDE = 'server-side', CLIENT_SIDE = 'client-side'
}
