import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {
  AbstractModelService,
  Application,
  ApplicationModel,
  Attribute,
  Button,
  Choice,
  ChoiceValue,
  CustomWebComponent,
  DisplayTransformation,
  Entity,
  EntityModel,
  Field,
  FieldResponse,
  FullCalendarEvent,
  FullCalendarResource,
  GuidedTour,
  GuidedTourStep,
  KanbanColumn,
  Layout,
  LayoutSection,
  ListColumn,
  Relation,
  Script,
  ScriptType,
  SectionField,
  SectionTab,
  Type,
  View,
  VirtualCollection,
  Wizard,
  WizardSection
} from '@wspsoft/frontend-backend-common';

import {Configuration} from '../../configuration';
import {BASE_PATH} from '../../variables';
import {AbstractService} from '../abstract-service';

import {EntityEnhancer} from '../util/entity-enhancer';
import {RuntimeModelService} from './runtime-model.service';

/**
 * we have to delegate these tasks to the runtime model, as we cannot extend 2 things
 */
@Injectable()
export class ModelService extends AbstractService implements AbstractModelService {
  public applications: { [key: string]: ApplicationModel };

  public constructor(httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string,
                     @Optional() configuration: Configuration, private runtimeModelService: RuntimeModelService) {
    super(httpClient, basePath, configuration);

    runtimeModelService.getClientData = () => this.getClientData();
    this.basePath = '/api/rest/private/portal/model';
  }

  public init(): Promise<void> {
    return this.runtimeModelService.init();
  }

  public getApplications(name?: string): ApplicationModel[] {
    return this.runtimeModelService.getApplications(name);
  }

  public getApplication(application: string): ApplicationModel {
    return this.runtimeModelService.getApplication(application);
  }

  public getTypes(name?: string): Type[] {
    return this.runtimeModelService.getTypes(name);
  }

  public getType(type: string): Type {
    return this.runtimeModelService.getType(type);
  }

  public getDisplayTransformations(idOrName?: string): DisplayTransformation[] {
    return this.runtimeModelService.getDisplayTransformations(idOrName);
  }

  public getDisplayTransformation(transformation: string): DisplayTransformation {
    return this.runtimeModelService.getDisplayTransformation(transformation);
  }

  public getGuidedTours(idOrName?: string): GuidedTour[] {
    return this.runtimeModelService.getGuidedTours(idOrName);
  }

  public getGuidedTour(tour: string): GuidedTour {
    return this.runtimeModelService.getGuidedTour(tour);
  }

  public getGuidedTourSteps(id?: string): GuidedTourStep[] {
    return this.runtimeModelService.getGuidedTourSteps(id);
  }

  public getGuidedTourStep(name: string): GuidedTourStep {
    return this.runtimeModelService.getGuidedTourStep(name);
  }

  public getWizard(type: string): Wizard {
    return this.runtimeModelService.getWizard(type);
  }

  public getWizardSection(nameOrId: string): WizardSection {
    return this.runtimeModelService.getWizardSection(nameOrId);
  }

  public getVirtualCollection(nameOrId: string): VirtualCollection {
    return this.runtimeModelService.getVirtualCollection(nameOrId);
  }

  public getTypeName(field: Field): string {
    return this.runtimeModelService.getTypeName(field);
  }

  public getChoice(name: string): Choice {
    return this.runtimeModelService.getChoice(name);
  }

  public getChoiceValue(choiceName: string, choiceValue: string): ChoiceValue {
    return this.runtimeModelService.getChoiceValue(choiceName, choiceValue);
  }

  public getEntities(name?: string): EntityModel[] {
    return this.runtimeModelService.getEntities(name);
  }

  public getEntity(entity: string): EntityModel {
    return this.runtimeModelService.getEntity(entity);
  }

  public getEntityByType(relation1: Relation): EntityModel {
    return this.runtimeModelService.getEntityByType(relation1);
  }

  public getScriptTriggers(entity: string): Script[] {
    return this.runtimeModelService.getScriptTriggers(entity);
  }

  public getLayout(nameOrUrl: string): Layout {
    return this.runtimeModelService.getLayout(nameOrUrl);
  }

  public getLayouts(): Layout[] {
    return this.runtimeModelService.getLayouts();
  }

  public getRelations(entity: string): Relation[] {
    return this.runtimeModelService.getRelations(entity);
  }

  public getRelation(entity: string, id: string): Relation {
    return this.runtimeModelService.getRelation(entity, id);
  }

  public getAttributes(entity: string): Attribute[] {
    return this.runtimeModelService.getAttributes(entity);
  }

  public getFields(entity: string, name?: string, substring?: boolean): FieldResponse {
    return this.runtimeModelService.getFields(entity, name, substring);
  }

  public getFieldInfo(entityIdOrName: string, fieldIdOrName: string): { field: Field; entity: EntityModel; own: boolean } {
    return this.runtimeModelService.getFieldInfo(entityIdOrName, fieldIdOrName);
  }

  public getField(entity: string, nameWithDotWalk: string): Field {
    return this.runtimeModelService.getField(entity, nameWithDotWalk);
  }

  public updateApplication(modelXml: string): Application;
  public updateApplication(modelXml: string): any {
    // eslint-disable-next-line prefer-const
    const {queryParameters, headers} = this.getHeaders();

    if (modelXml === null || modelXml === undefined) {
      throw new Error('Required parameter modelXml was null or undefined when calling updateApplication.');
    }

    const url = `${this.basePath}/applications`;

    return this.doPut<Application>(url, modelXml, queryParameters, headers);
  }

  public getClientData(): Promise<ApplicationModel[]>;
  public getClientData(): Promise<any> {
    // eslint-disable-next-line prefer-const
    const {queryParameters, headers} = this.getHeaders();
    const url = `${this.basePath}/${(window as any).modelHash}/clientData`;

    return this.doGet<ApplicationModel[]>(url, queryParameters, headers);
  }

  public async getEntitiesLocalized(name?: string): Promise<EntityModel[]> {
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();
    if (name) {
      queryParameters = queryParameters.set('name', name);
    }


    const enhancer = new EntityEnhancer();
    return (await this.doGet<Entity[]>(`${this.basePath}/entities`, queryParameters, headers)).map(entity =>
      enhancer.designerEnhancement(this, entity)
    );
  }

  public async getFieldsLocalized(entity: string, name?: string): Promise<FieldResponse> {
    if (entity === null || entity === undefined) {
      throw new Error('Required parameter entity was null or undefined when calling getFields.');
    }
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();

    queryParameters = queryParameters.set('name', name);
    const fieldResponse = await this.doGet<FieldResponse>(`${this.basePath}/entities/${entity}/fields`, queryParameters, headers);
    // write back the path to the field
    fieldResponse.fields.forEach(value => value.path = fieldResponse.path);

    // grab the enhanced version
    fieldResponse.entity = this.getEntity(fieldResponse.entity.id);
    return fieldResponse;
  }

  public iterateObject(hostEntityMeta: Entity, currentPath: string, path: string[],
                       fn: (field: Field, entityMeta: Entity, last: boolean, column?: string) => void): void {
    this.runtimeModelService.iterateObject(hostEntityMeta, currentPath, path, fn);
  }

  public findDescendants(hostEntityMeta: EntityModel): EntityModel[] {
    return this.runtimeModelService.findDescendants(hostEntityMeta);
  }

  public getGlobalScripts(): Script[] {
    return this.runtimeModelService.getGlobalScripts(ScriptType.CLIENT_SIDE);
  }

  public getAllGlobalScripts(): Script[] {
    const scripts: Script[] = this.runtimeModelService.getGlobalScripts(ScriptType.CLIENT_SIDE);
    scripts.push(...this.runtimeModelService.getGlobalScripts(ScriptType.SERVER_SIDE).filter(x => x.type === ScriptType.SERVER_SIDE));
    return scripts;
  }


  public getFieldType(field: Field): Type {
    return this.runtimeModelService.getFieldType(field);
  }

  public getDefaultListColumn(entityId: string, fieldName: string): ListColumn {
    return this.runtimeModelService.getDefaultListColumn(entityId, fieldName);
  }

  public getKanbanColumn(id: string): KanbanColumn {
    return this.runtimeModelService.getKanbanColumn(id);
  }

  public getListColumn(id: string): ListColumn {
    return this.runtimeModelService.getListColumn(id);
  }

  public getLayoutSection(id: string): LayoutSection {
    return this.runtimeModelService.getLayoutSection(id);
  }

  public getSectionField(id: string): SectionField {
    return this.runtimeModelService.getSectionField(id);
  }

  public getSectionTab(id: string): SectionTab {
    return this.runtimeModelService.getSectionTab(id);
  }

  public getButton(id: string): Button {
    return this.runtimeModelService.getButton(id);
  }

  public getWebComponent(id: string): CustomWebComponent {
    return this.runtimeModelService.getWebComponent(id);
  }

  public getWebComponents(): CustomWebComponent[] {
    return this.runtimeModelService.getWebComponents();
  }

  public getView(nameOrId: string): View {
    return this.runtimeModelService.getView(nameOrId);
  }

  public getFullCalendarResource(idOrName: string): FullCalendarResource {
    return this.runtimeModelService.getFullCalendarResource(idOrName);
  }

  public getFullCalendarResources(idOrName?: string): FullCalendarResource[] {
    return this.runtimeModelService.getFullCalendarResources(idOrName);
  }

  public getFullCalendarEvent(idOrName: string): FullCalendarEvent {
    return this.runtimeModelService.getFullCalendarEvent(idOrName);
  }

  public getFullCalendarEvents(idOrName?: string): FullCalendarEvent[] {
    return this.runtimeModelService.getFullCalendarEvents(idOrName);
  }
}
