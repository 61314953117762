import {ScriptType} from '../xml/script';
import {I18n} from './i18n';
import {KolibriEntity} from './kolibri-entity';

export abstract class FlowElement extends KolibriEntity {
  public title?: string;

  // exclusive for FlowLoop and FlowParallel
  public caseData?: Record<string, FlowElement[]>;

  //exclusive for FlowJump
  public target?: string;

  // internal
  public parent?: FlowElement;
  public workflowIdentifier?: string;
}

export abstract class FlowElementCategory extends KolibriEntity {
  public name?: I18n;
  public order?: number;
  public parent?: FlowElementCategory;
  public parentId?: string;
  public children?: FlowElementCategory[];
}

export abstract class FlowElementConfiguration extends KolibriEntity {
  public condition?: JSON;
  public entity?: string;
  public order?: number;
  public elementType?: FlowElementType;
  public lowCodeContent?: string;
  public workflowContent?: string;
  public inlineContent?: string;
  public type?: ScriptType;
  public workflowOnly?: boolean;
  public isScriptTask?: boolean;
  public categoryId?: string;
  public category?: FlowElementCategory;
}

export enum FlowElementType {
  ACTION = 'action', STRUCTURE = 'structure'
}
