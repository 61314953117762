import {WritableStreamDefaultWriter} from 'stream/web';

export enum LayoutViewMode {
  FULL_PAGE,
  DIALOG,
  PAGE_RIGHT,
  NESTED,
  WIDGET,
  PRESENTATION,
  TAB  = 6
}
