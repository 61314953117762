import {
  EmailRule,
  EntityToListColumn,
  FullCalendarEvent,
  KanbanColumn,
  Layout,
  LayoutCondition,
  LayoutSection,
  LevelAgreement,
  ListColumn,
  MatrixView,
  SectionField,
  SectionTab,
  VirtualCollection,
  Workflow
} from '../..';
import {Attribute} from './attribute';
import {DesignerEntity} from './designer.entity';
import {FullCalendarResource} from './full-calendar-resource';
import {Relation} from './relation';
import {Script} from './script';


export abstract class Entity extends DesignerEntity {
  public abstract?: boolean;
  public hideRepImage?: boolean;
  public enableAutoIndex?: boolean;
  public showOnSelect?: boolean;
  public audited?: boolean;
  public subscribable?: boolean;
  public subscriptionDuration?: number;
  public virtual?: boolean;
  public virtualCollection?: VirtualCollection;
  public virtualCollectionId?: string;
  public infoViewEnabled?: boolean;
  public descendantViewEnabled?: boolean;
  public prettyTime?: boolean;
  public activityStreamConditionScript?: string;
  public allowCustomersSwitch?: boolean;
  public conflictCase?: boolean;
  public activityStream?: boolean;
  public webserviceEnabled?: boolean;
  public webserviceReadEnabled?: boolean;
  public webserviceCreateEnabled?: boolean;
  public webserviceUpdateEnabled?: boolean;
  public webserviceDeleteEnabled?: boolean;
  public inMemoryCaching?: boolean;
  public templateAllowed?: boolean;
  public mappingEntity?: boolean;
  public enableTenancy?: boolean;
  public baseId?: string;
  public representativeAttribute?: Attribute;
  public destinationRelationId?: string;
  public sourceRelationId?: string;
  public createLayoutId?: string;
  public defaultLayoutId?: string;
  public defaultListId?: string;

  public layoutConditions?: LayoutCondition[];
  public relations?: Relation[];
  public attributes?: Attribute[];
  public scripts?: Script[];
  public layouts?: Layout[];
  public emailRules?: EmailRule[];
  public workflows?: Workflow[];
  public fullCalendarResources?: FullCalendarResource[];
  public fullCalendarEvents?: FullCalendarEvent[];
  public levelAgreements?: LevelAgreement[];
  public matrixViews?: MatrixView[];
  public listColumns?: ListColumn[];
  public kanbanColumns?: KanbanColumn[];
  public layoutSections?: LayoutSection[];
  public sectionFields?: SectionField[];
  public tabs?: SectionTab[];
  public entityToListColumns?: EntityToListColumn[];
}
