import {WorkflowNode} from '../database/workflowInstance';
import {DesignerEntity} from './designer.entity';


export abstract class Workflow extends DesignerEntity {
  public format?: string;
  public entityId?: string;
  public startCondition?: any;
  public cancelCondition?: any;
  public scope?: ScopeEnum;
  public definition?: { [id: string]: WorkflowNode };
  public payload?: any;
}

export enum ScopeEnum {
  Defined = 'defined',
  Inherited = 'inherited',
  Explicit = 'explicit'
}
