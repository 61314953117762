import {Directive, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {SelectItemGroup} from 'primeng/api';
import {Subscription} from 'rxjs';
import {UiUtil} from '../../../util/ui-util';
import {CustomInput} from '../custom-input';
import {PrimengCustomAutocompleteComponent} from './primeng-custom-autocomplete/primeng-custom-autocomplete.component';

// noinspection AngularMissingOrInvalidDeclarationInModule
@Directive()
export abstract class AutoComplete<T, E = T> extends CustomInput<T> implements OnDestroy {
  @Input()
  public clearable: boolean = true;
  @Input()
  public size: number = 20;
  @Input()
  public readonly: boolean = true;
  @Input()
  public dropdown: boolean = true;
  @Input()
  public forceSelection: boolean = true;
  @Input()
  public forceNotEmpty: boolean = true;
  @Input()
  public displayFields: string[];
  @Input()
  public queryFields: string[];
  /**
   * display always a value
   */
  @Input()
  public defaultEntry: (query: string) => any;
  @Output()
  public onSelect: EventEmitter<any> = new EventEmitter();
  @Output()
  public onShow: EventEmitter<any> = new EventEmitter();
  @Output()
  public onHide: EventEmitter<any> = new EventEmitter();
  @Output()
  public onUnselect: EventEmitter<any> = new EventEmitter();
  @ViewChild(PrimengCustomAutocompleteComponent, {static: false})
  protected autocomplete: PrimengCustomAutocompleteComponent;
  protected subscription: Subscription;
  protected lastQuery: string;


  private psuggestions: E[] | SelectItemGroup[];
  @Input()
  public get suggestions(): E[] | SelectItemGroup[] {
    return this.psuggestions;
  }

  public set suggestions(value: E[] | SelectItemGroup[]) {
    this.psuggestions = value;
    if (!this.lastQuery) {
      this.doAutoSelect();
    }
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  /**
   * Sets the value to the only suggestion if there is only one, the autoselect boolean is set and its not a multiple
   * Emits the value after setting it.
   * Autocompletes can be grouped. The autoselect works as well if there is only one group and one item in that group.
   * In the case if there is no value and its forced to be not empty, the old value is used (if exists).
   * After setting the value, validate function is called.
   */
  public doAutoSelect(): void {
    if (this.psuggestions?.length === 1 && this.require && !this.multiple) {
      if (UiUtil.isSelectItemGroup(this.psuggestions[0])) {
        if ((this.psuggestions as SelectItemGroup[])[0].items?.length === 1) {
          this.value = (this.psuggestions as SelectItemGroup[])[0].items[0] as any;
          this.onSelect.emit(this.value);
        }
      } else {
        // suggestions[0] should be always from type T
        // @ts-ignore
        this.value = this.psuggestions[0];
        this.onSelect.emit(this.value);
      }
    }

    if (this.forceNotEmpty && !this.value && this.oldValue) {
      this.restoreOldValue();
    }
  }

  public clear(): void {
    this.psuggestions = null;
    this.originalValueOld = undefined;
  }

  protected restoreOldValue(): void {
    this.value = this.oldValue;
  }
}
