import {_} from '@wspsoft/underscore';
import * as uuidv4 from 'uuid-random';
import {AbstractKolibriScriptExecutor} from '../api/abstract-kolibri-script-executor';
import {FlowElement, FlowElementConfiguration} from '../model/database/flow-element';
import {WorkflowNode} from '../model/database/workflowInstance';
import {AbstractModelService} from '../service/coded/abstract-model.service';
import {LowCodeUtil} from '../util/low-code-util';
import {AbstractCriteriaJsQueryCompiler} from './criteria-js-query-compiler';

export abstract class LowCodeJsCompiler extends AbstractCriteriaJsQueryCompiler {
  protected constructor(modelService: AbstractModelService, protected scriptExecutor: AbstractKolibriScriptExecutor) {
    super(modelService);
  }

  public abstract get flowConfigurations(): { [flowEntity: string]: FlowElementConfiguration };

  /**
   * compile all given flow elements to a single script
   */
  protected compileFlowElements(flowElements: FlowElement[]): string {
    if (!flowElements) {
      return '';
    }

    return flowElements.map(flowElement => this.compileFlowElement(flowElement)).join('\n');
  }

  /**
   * compile all given flow elements and turn them into a workflowModel for the workflow engine
   */
  protected compileWorkflowElements(query: { flowElements: FlowElement[], workflowModel: any, nestedParentElementId: string }): { [id: string]: WorkflowNode } {
    if (!query.flowElements) {
      return {};
    }

    this.convertJumpElements(query.flowElements);

    const compiledWorkflowModel = query.workflowModel ?? {};
    for (const [i, flowElement] of query.flowElements.entries()) {
      this.compileWorkflowElement(flowElement, query.flowElements[i + 1], query.nestedParentElementId, compiledWorkflowModel);
    }
    return compiledWorkflowModel;
  }

  /**
   * Find all elements in the given flowElements that have the entityClass "FlowJump"
   * @param {FlowElement[]} flowElements
   * @param {FlowElement[]} jumpElements
   * @private
   */
  private findJumpElements(flowElements: FlowElement[], jumpElements: FlowElement[]): void {
    for (const flowElement of flowElements) {
      if (flowElement.entityClass === 'FlowJump') {
        jumpElements.push(flowElement);
      }

      LowCodeUtil.iterateFlowElement(flowElement, (nextElements) => this.findJumpElements(nextElements, jumpElements))
    }
  }

  /**
   * Find the target element of the FlowJump and replace its workflowIdentifier with the one of the found target element
   * @param {FlowElement[]} flowElements
   * @param {FlowElement} jumpElement
   * @private
   */
  private findTargetElement(flowElements: FlowElement[], jumpElement: FlowElement): void {
    for (const flowElement of flowElements) {
      if (flowElement.id === jumpElement.target) {
        jumpElement.workflowIdentifier = flowElement.workflowIdentifier;
      }

      LowCodeUtil.iterateFlowElement(flowElement, (nextElements) => this.findTargetElement(nextElements, jumpElement))
    }
  }

  /**
   * Search for all FlowJump elements and replace their workflowIdentifier with the workflowIdentifier of their target element
   * @param {FlowElement[]} flowElements
   * @private
   */
  private convertJumpElements(flowElements: FlowElement[]): void {
    const jumpElements: FlowElement[] = [];
    this.findJumpElements(flowElements, jumpElements);

    for (const jumpElement of jumpElements) {
      this.findTargetElement(flowElements, jumpElement);
    }
  }

  /**
   * compile a flow element to a script
   */
  private compileFlowElement(flowElement: FlowElement): string {
    const config = this.flowConfigurations[flowElement.entityClass];
    const lowCodeContent = config.lowCodeContent;
    return this.scriptExecutor.runScript<string>(lowCodeContent, {
      record: flowElement
    }, undefined, `FlowElementConfiguration:${flowElement.entityClass}:lowCodeContent`, false, true).result as string;
  }

  /**
   * run the workflowContent for a flowElement
   */
  private compileWorkflowElement(flowElement: FlowElement, nextElement: FlowElement, nestedParentElementId: string,
                                 workflowModel: { [id: string]: WorkflowNode }): { [id: string]: WorkflowNode } {
    if (_.isEmpty(workflowModel)) {
      // @ts-ignore
      const startEventId = `FlowStart${uuidv4().replaceAll('-', '')}`;
      workflowModel[startEventId] = {
        id: startEventId,
        type: 'flowStart',
        outgoing: [flowElement.workflowIdentifier]
      };
    }

    const config = this.flowConfigurations[flowElement.entityClass];

    let outgoingId = nextElement?.workflowIdentifier ?? nestedParentElementId;
    if (!outgoingId) {
      // @ts-ignore
      outgoingId = `FlowEnd${uuidv4().replaceAll('-', '')}`;
      workflowModel[outgoingId] = {
        id: outgoingId,
        type: 'flowEnd'
      };
    }

    // if element is configured as a simple scriptTask, run the lowCodeContent and use the script in a GenericTask
    if (config.isScriptTask) {
      const script = this.scriptExecutor.runScript(config.lowCodeContent, {
        record: flowElement
      }, undefined, `FlowElementConfiguration:${config.entity}:lowCodeContent`, false, true).result as any;

      workflowModel[flowElement.workflowIdentifier] = {
        id: flowElement.workflowIdentifier,
        type: 'flowGenericTask',
        script,
        outgoing: [outgoingId]
      };

      return workflowModel;
    }

    return this.scriptExecutor.runScript(config.workflowContent, {
      record: flowElement,
      data: {
        nextElement,
        outgoingId,
        workflowModel
      }
    }, undefined, `FlowElementConfiguration:${config.entity}:workflowContent`, false, true).result as any;
  }
}
