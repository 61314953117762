<ng-container *ngIf="convertValue !== undefined && !renderType && multiple else withRenderType">
  <ng-container *ngFor="let value of convertValue; let i = index">
    <ng-container *ngTemplateOutlet="translationData; context: {$implicit: value, index: i}"></ng-container>
  </ng-container>
</ng-container>
<ng-template #withRenderType>
  <ng-container *ngTemplateOutlet="translationData; context: {$implicit: convertValue}"></ng-container>
</ng-template>
<ng-template #translationData let-i="index" let-translation>
  <ng-container [ngSwitch]="renderType">
    <ng-container *ngSwitchCase="RenderType.STEPS">
      <ui-entity-steps [compactMode]="true" [data]="data" [entityName]="data.entityClass" [phaseProgressValueField]="field"
                       [phaseProgressValueScript]="transformScript" [style]="transformedStyle"></ui-entity-steps>
    </ng-container>
    <ng-container *ngSwitchCase="RenderType.HTML">
      <div [innerHTML]="translatedValue | safe: 'html'" [ngStyle]="transformedStyle"></div>
    </ng-container>
    <ng-container *ngSwitchCase="RenderType.PROGRESSBAR">
      <div class="one-progressbar__label">{{translatedValue ? translatedValue : '0'}}%</div>
      <p-progressBar [showValue]="false" [style]="transformedStyle" [value]="translatedValue"></p-progressBar>
    </ng-container>
    <ng-container *ngSwitchCase="RenderType.PILL">
      <ng-container *ngIf="multiple">
        <ng-container *ngFor="let valueOfTranslation of translation; let i = index">
          <ui-pill [color]="transformedColor" [icon]="transformedIcon" [label]="valueOfTranslation" [ngStyle]="transformedStyle" [rounded]="rounded"></ui-pill>
        </ng-container>
      </ng-container>
      <ui-pill *ngIf="!multiple" [color]="transformedColor" [icon]="transformedIcon" [label]="translation" [ngStyle]="transformedStyle"
               [rounded]="rounded"></ui-pill>
    </ng-container>
    <ng-container *ngSwitchCase="RenderType.SENSITIVE">
      <small><i>Sensitive</i></small>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngIf="translation?.length || showIcon && !showText" [ngSwitch]="typeName.toLowerCase()">
        <ng-container *ngSwitchCase="'choice'">
          <ui-choice-translation [data]="data" [field]="field" [index]="i" [meta]="meta" [multiple]="multiple" [ngStyle]="transformedStyle"
                                 [translatedValue]="translation"></ui-choice-translation>
        </ng-container>
        <ng-container *ngSwitchCase="'kolibrientity'">
          <ng-container [ngSwitch]="typeService.getKolibriType(meta)">
            <ng-container *ngSwitchCase="'attachment'">
              <ui-rep-image [data]="data" [field]="field" [margin]="false"></ui-rep-image>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <i *ngIf="entityValue?.icon && showIcon" [ngStyle]="{color: entityValue.color}"
                 class="one-autocomplete__column--icon fa-fw {{entityValue.icon}}"></i>
              <ui-rep-image *ngIf="!hideRepImage && (entityValue?.repImageId || entityValue?.initials)" [data]="entityValue" field="repImage"></ui-rep-image>
              <span [ngStyle]="transformedStyle">{{translation}}</span>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <ui-pill *ngIf="multiple" [label]="translation"></ui-pill>
          <span *ngIf="!multiple" [ngStyle]="transformedStyle">{{translation}}</span>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>
