<ui-input-wrapper [(editMode)]="editMode" [disable]="disable" [filled]="nativeInput.filled" [helpMessage]="helpMessage" [label]="label" [linkify]="linkify"
                  [renderInputGroup]="renderInputGroup" [require]="require">
  <ui-primeng-custom-autocomplete #nativeInput (completeMethod)="onComplete($event)" (onForceClear)="clear();" (onSelect)="onSelect.emit($event);"
                                  (onUnselect)="onUnselect.emit($event)" [(ngModel)]="value" [clearable]="clearable" [disabled]="disable" [dropdown]="dropdown"
                                  [field]="field" [forceSelection]="forceSelection" [minLength]="0" [multiple]="multiple" [required]="require" [size]="size"
                                  [suggestions]="suggestions" appendTo="body" emptyMessage="{{'AutoComplete.NoResults' | translate}}" name="native">
    <ng-template *ngIf="showIcons" let-item pTemplate="item">
      <i *ngIf="item.icon" [className]="'one-autocomplete__column--icon fa-fw ' + item.icon" [ngStyle]="{color: item.color}"></i>
      <span class="p-autocomplete-token-label">{{item[field]}}</span>
    </ng-template>
  </ui-primeng-custom-autocomplete>
</ui-input-wrapper>

