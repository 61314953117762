import {AggregationFunction} from './aggregation-function';


import {DesignerEntity} from './designer.entity';

export enum FilterType {
  MULTISELECT = 'multiSelect',
  STANDARD = 'standard',
  SLIDER = 'slider'
}

export enum RenderType {
  HTML = 'html',
  STEPS = 'steps',
  PROGRESSBAR = 'progressBar',
  DISPLAYTRANSFORM = 'displayTransform',
  PILL = 'pill',
  SENSITIVE = 'sensitive'
}

export abstract class ListColumn extends DesignerEntity {
  public filterable?: boolean;
  public editable?: boolean;
  public redirectUrl?: string;
  public resizable?: boolean;
  public sortable?: boolean;
  public rounded?: boolean;
  public entityId?: string;
  public type?: string;
  public exportable?: string;
  public rendered?: string;
  public toggleable?: boolean;
  public aggregation?: AggregationFunction;
  public filterType?: FilterType;
  public defaultFilterOperator?: string;
  public renderType?: RenderType;
  public transformScript?: string;
  public displayTransformId?: string;
  public sliderMinValue?: number;
  public sliderMaxValue?: number;
}
