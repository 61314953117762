import {KolibriEntity} from './kolibri-entity';
import {MessageTemplateI18n} from './message-template-i18n';
import {TemplateParameter} from './template-parameter';


export abstract class MessageTemplate extends KolibriEntity {
  public messageTemplateI18ns?: MessageTemplateI18n[];
  public templateParameters?: TemplateParameter[];
  public description?: string;
  public name?: string;
  public order?: number;
  public type?: MessageType;
}

export enum MessageType {
  message = 'message', email = 'email', markdown = 'markdown', html = 'html'
}
