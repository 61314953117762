import {Attribute} from '../../../model/xml/attribute';
import {Entity} from '../../../model/xml/entity';
import {Relation} from '../../../model/xml/relation';
import {Script} from '../../../model/xml/script';
import {enumerable} from '../../../util/annotations';
import {_} from '@wspsoft/underscore';
import {EntityModel} from '../../entities/entity-model';

export class DesignerHandler {
  @enumerable(false)
  protected readonly _enhancer: any;

  public constructor(entity: Entity, enhancer: any) {
    this._enhancer = enhancer;
    Object.assign(this, entity);
  }

  /**
   * we will merge a kolibri entity with this handler
   */
  public get record(): Entity {
    return this as any as Entity;
  }

  public get allRelations(): Relation[] {
    const result = [].concat(this.record.relations);
    if (this.ancestor) {
      result.unshift(...this.ancestor.allRelations);
    }
    return _.uniqBy(result, 'name');
  }

  public get allAttributes(): Attribute[] {
    const result = [].concat(this.record.attributes);
    if (this.ancestor) {
      result.unshift(...this.ancestor.allAttributes);
    }
    return _.uniqBy(result, 'name');
  }

  public get allScripts(): Script[] {
    const result = [].concat(this.record.scripts);
    if (this.ancestor) {
      result.unshift(...this.ancestor.allScripts);
    }
    return _.sortBy(result, 'order');
  }

  public get allEmailRules(): Script[] {
    const result = [].concat(this.record.emailRules);
    if (this.ancestor) {
      result.unshift(...this.ancestor.allEmailRules);
    }
    return _.sortBy(result, 'order');
  }

  public get descendants(): (DesignerHandler & Entity)[] {
    // this uses relations normally in frontend so pretend an upward relation exists
    return this._enhancer.modelService.findDescendants(this.record) as any as (DesignerHandler & Entity)[];
  }

  public get ancestor(): DesignerHandler & Entity {
    // this uses relations normally in frontend so pretend an upward relation exists
    // also use legacy solution for caching, kolibri entity has no base
    const targetId = this.record.baseId;
    return this._enhancer.modelService.getEntityByType({targetId}) as any as DesignerHandler & Entity;
  }

  public get ancestors(): (DesignerHandler & Entity)[] {
    const result = [];
    result.push(this);
    if (this.ancestor) {
      result.push(...this.ancestor.ancestors);
    }
    return result;
  }

  public get representativeAttribute(): Attribute {
    if (!this.record.attributes) {
      return null;
    }
    const a = this.record.attributes.filter(value => value.isRepresentative)[0];

    /*
     * if the current entity has no representative anyObject go and get is from the ancestor
     * if no representative Attribute is defined then return null (should never be reached)
     */
    if (!a && this.ancestor) {
      return this.ancestor.representativeAttribute;
    } else {
      return a ? a : _.find(this.allAttributes, {name: 'id'});
    }
  }

  public isDescendantOf(entityModel: EntityModel): boolean {
    // this uses relations normally in frontend so pretend an upward relation exists
    // @ts-ignore
    if (!this.ancestor) {
      return false;
    }
    if (this.ancestor.id === entityModel.id) {
      return true;
    }
    return this.ancestor.isDescendantOf(entityModel);
  }

  public isAncestorOf(entityModel: EntityModel): void {
    // this uses relations normally in frontend so pretend an upward relation exists
    // @ts-ignore
    return entityModel.isDescendantOf(this);
  }
}
