import {animate, style, transition, trigger} from '@angular/animations';
import {ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {_} from '@wspsoft/underscore';
import {AutoComplete} from 'primeng/autocomplete';
import {DomHandler} from 'primeng/dom';

@Component({
  selector: 'ui-primeng-custom-autocomplete',
  templateUrl: './primeng-custom-autocomplete.component.html',
  styleUrls: ['../autocomplete.scss', './primeng-custom-autocomplete.component.scss'],
  animations: [
    trigger('overlayAnimation', [
      transition(':enter', [
        style({opacity: 0, transform: 'scaleY(0.8)'}),
        animate('{{showTransitionParams}}')
      ]),
      transition(':leave', [
        animate('{{hideTransitionParams}}', style({opacity: 0}))
      ])
    ])
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.p-inputwrapper-filled]': 'filled',
    '[class.p-inputwrapper-focus]': 'focus && !disabled'
  },
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PrimengCustomAutocompleteComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PrimengCustomAutocompleteComponent extends AutoComplete implements OnInit {
  @Input()
  public clearable: boolean = true;
  @Input()
  public completeOnFocus: boolean = true;
  @Input()
  public showEmptyMessage: boolean = true;
  @Input()
  public autoHighlight: boolean = false;
  @Input()
  public fields: string[];
  @Output()
  public onForceClear: EventEmitter<void> = new EventEmitter<void>();
  public prevQuery: string = '';
  private currentOffset: number = 0;

  public ngOnInit(): void {
    if (!this.fields?.length) {
      this.fields = [this.field];
    }
  }

  public onInput(event: any): void {
    // When an input element with a placeholder is clicked, the onInput event is invoked in IE.
    if (!this.inputKeyDown && DomHandler.isIE()) {
      return;
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    const value = event.target.value;
    if (!this.multiple && !this.forceSelection) {
      this.onModelChange(value);
    }
    if (value.length === 0 && !this.multiple) {
      this.clear();
      this.hide();
    }
    this.timeout = setTimeout(() => {
      this.search(event, value);
    }, this.delay);
    this.updateFilledState();
    this.inputKeyDown = false;
  }

  public handleDropdownClick(event: any): void {
    const focus = this.completeOnFocus;
    this.completeOnFocus = false;
    super.handleDropdownClick(event);
    this.completeOnFocus = focus;
    event.cb();
  }

  public hide(): void {
    super.hide();
    this.currentOffset = 0;
    this.prevQuery = '';
  }

  public search(event: any, query: string, scroll?: boolean): void {
    if (_.isNull(query)) {
      return;
    }

    if (scroll) {
      this.currentOffset += 30;
    } else {
      this.currentOffset = 0;
    }
    this.autoHighlight = !!query;
    this.loading = true;
    this.prevQuery = query;
    this.completeMethod.emit({
      originalEvent: event,
      query,
      offset: this.currentOffset
    });
  }

  public isDropdownClick(event: any): boolean {
    if (this.dropdown) {
      // click on the icon, the p button or the real button
      return event.target === this.dropdownButton.nativeElement ||
        event.target.parentElement === this.dropdownButton.nativeElement ||
        event.target.parentElement?.parentElement === this.dropdownButton.nativeElement;
    } else {
      return false;
    }
  }

  public clear(): void {
    this.writeValue(null);
    this.onModelChange();
    this.onModelTouched();
    this.onSelect.emit();
    this.onForceClear.emit();
    this.focusInput();
  }

  public bindScrollListener(): void {
  }

  public unbindScrollListener(): void {
  }

  public selectItem(option: any, focus: boolean = false): void {
    super.selectItem(option, focus);
  }
}
