<div (focusin)="hasFocus = true" (focusout)="hasFocus = false" (mouseenter)="hovered = true" (mouseleave)="hovered = false" [ngClass]="{
'p-inline-label': hasInlineLabel,
'p-float-label': !hasInlineLabel,
'p-centered-label': hasCenteredLabel,
'one-input__group': renderInputGroup,
'one-input__group--reverse': hasReversedLabelPosition,
'p-state-filled': filled || hasLabelAlwaysUp
}" class="{{showMessage && severity ? 'p-state-' + severity : ''}}">

  <label *ngIf="label">{{label}}{{require && !disable ? ' *' : ''}}
    <ui-button (clicked)="editModeChange.emit(!this.editMode); $event.cb()" *ngIf="isSpecialFormat && hovered && !disable" [type]="['icon-only','inline']"
               class="one-input__button--inline" icon="fas fa-fw fa-pen" label="{{'Input.ToggleFormattedDisplay' | translate}}"></ui-button>
  </label>

  <ng-container *ngIf="isSpecialFormat && !editMode">
    <div [innerHTML]="linkifyValue | safe: 'html'" [ngClass]="{'one-input--overflow': linkifyRows>1}" class="one-input" style="--rows: {{linkifyRows}}"></div>
  </ng-container>

  <ng-container *ngIf="!isSpecialFormat || editMode">
    <ng-content></ng-content>
  </ng-container>

  <p-message *ngIf="showMessage" [severity]="severity" [text]="message"
             styleClass="{{renderInputGroup ? 'p-inline-message--low' : 'p-inline-message--lower'}}"></p-message>
</div>
