<ng-template #queryBuilderContent>
  <div class="one-conditionbuilder__rule-content">
    <ui-field-autocomplete (convertChange)="selectField($event)" (onSelect)="selectField($event)" [(ngModel)]="columnName" [allowDotWalk]="queryBuilderDataService.allowDotWalk"
                           [disable]="disable" [entityName]="queryBuilderDataService.entityMeta.name"
                           [localized]="queryBuilderDataService.localized" [require]="true" [search]="queryBuilderDataService.search"
                           class="one-conditionbuilder__rule-filter" label="{{'QueryBuilder.Sort.Field' | translate}}" name="field"></ui-field-autocomplete>

    <ui-autocomplete (completeMethod)="searchOrder($event)" (onSelect)="this.forceUpdate()" *ngIf="selectedField" [(ngModel)]="value.order"
                     [converter]="operatorConverter" [disable]="disable" [dropdown]="true" [require]="true" [suggestions]="orders"
                     class="one-conditionbuilder__rule-operator" field="label" label="{{'QueryBuilder.Sort.Order.Tooltip' | translate}}"
                     name="order"></ui-autocomplete>
  </div>

  <div class="one-conditionbuilder__actions">
    <ui-button (clicked)="removeOrder()" *ngIf="deletable" [disable]="disable" [showDelay]="150" [spinner]="false"
               [type]="['icon-only', 'red']" class="one-conditionbuilder__delete" data-delete="rule" icon="fas fa-fw fa-trash"
               label="{{'QueryBuilder.Sort.Remove' | translate}}" tooltipPosition="top"></ui-button>
    <ui-button (onDragEnd)="moveOrder()" (onDragStart)="moveOrder(value)" *ngIf="draggable" [disable]="disable" [draggable]="!disable"
               [spinner]="false" class="one-conditionbuilder__drag" dragEffect="move" icon="fas fa-fw fa-arrows-alt" label="{{'QueryBuilder.Sort.Move' | translate}}" pDraggable="querySortElement"
               tooltipPosition="top" type="icon-only"></ui-button>
  </div>
</ng-template>

<ng-template #breadCrumbContent>
  <span class="one-filter-nav__item-text one-filter-nav__item-text--sort">
    {{breadcrumbString}}
  </span>
  <p-menu #menu [model]="breadcrumbMenu" [popup]="true" appendTo="body"></p-menu>
  <ui-button (clicked)="menu.toggle($event.originalEvent); $event.cb();" [disable]="disable" class="one-filter-nav__item-button" icon="fa fa-fw fa-ellipsis-v"
             type="icon-only"></ui-button>
</ng-template>

<ng-container *ngIf="breadcrumb" [ngTemplateOutlet]="breadCrumbContent"></ng-container>
<ng-container *ngIf="!breadcrumb" [ngTemplateOutlet]="queryBuilderContent"></ng-container>
