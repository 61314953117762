import {DesignerEntity} from './designer.entity';
import {SectionTabToLayoutSection} from './section-tab-to-layout-section';

/**
 * Connection between configured fields and sections
 */
export abstract class SectionTab extends DesignerEntity {
  public lazyLoaded?: boolean;
  public rendered?: any;
  public entityId?: string;
  public sections: SectionTabToLayoutSection[];
}
