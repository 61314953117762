<ui-autocomplete [(ngModel)]="value" [dropdown]="true" [require]="true" [suggestions]="availablePresets" class="rule-operator-container" field="label"
                 name="preset"></ui-autocomplete>

<ng-container *ngIf="value?.name === 'Custom'">
  <ui-input-number (onChange)="updateValue()" [(ngModel)]="value.amount" [locale]="translate.currentLang" label="{{'DateRange.Amount' | translate}}"
                   name="amount"></ui-input-number>
  <ui-autocomplete (onChange)="updateValue()" [(ngModel)]="value.unit" [converter]="dateUnitConverter" [dropdown]="true" [forceSelection]="true"
                   [require]="true" [suggestions]="availableDateUnits" class="rule-operator-container" field="label" name="unit"></ui-autocomplete>
  <p-checkbox (onChange)="updateValue()" [(ngModel)]="value.fromToday" [binary]="true" label="{{'DateRange.FromToday' | translate}}"
              name="fromToday"></p-checkbox>
</ng-container>
