<ui-input-wrapper [(editMode)]="editMode" [disable]="disable" [filled]="nativeInput.filled" [helpMessage]="helpMessage" [label]="label" [linkify]="linkify"
                  [require]="require">
  <ui-primeng-custom-autocomplete #nativeInput (completeMethod)="onComplete($event)" (onForceClear)="clear();" (onHide)="onHide.emit($event)"
                                  (onSelect)="onSelect.emit($event)" (onShow)="onShow.emit($event)" [(ngModel)]="value" [disabled]="disable"
                                  [dropdown]="dropdown" [fields]="displayFields" [forceSelection]="forceSelection" [group]="!!groupBy" [inputStyle]="styleData"
                                  [multiple]="multiple" [required]="require" [size]="size" [suggestions]="suggestions" appendTo="body"
                                  emptyMessage="{{'AutoComplete.NoResults' | translate}}" field="representativeString" name="native">
    <ng-template *ngIf="!!groupBy" let-group pTemplate="group">
      <ui-field-translator [data]="group.items[0]" [field]="groupBy" [translateNull]="true"></ui-field-translator>
    </ng-template>
    <ng-template let-item pTemplate="selectedItem">
      <ng-container *ngTemplateOutlet="selectedItemTemplate; context: {$implicit: item}"></ng-container>
    </ng-template>
    <ng-template let-item pTemplate="item">
      <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
    </ng-template>
  </ui-primeng-custom-autocomplete>
</ui-input-wrapper>

<ng-template #itemTemplate let-item>
  <ui-field-translator [data]="item" field="representativeString" typeName="KolibriEntity"></ui-field-translator>
  <ng-container *ngIf="displayFields">
    <ui-field-translator *ngFor="let field of displayFields" [data]="item" [field]="field"></ui-field-translator>
  </ng-container>
</ng-template>
<ng-template #selectedItemTemplate let-item>
  <ui-field-translator [data]="item" [showIcon]="false" field="representativeString" typeName="KolibriEntity"></ui-field-translator>
  <ng-container *ngIf="displayFields">
    <ui-field-translator *ngFor="let field of displayFields" [data]="item" [field]="field"></ui-field-translator>
  </ng-container>
</ng-template>
