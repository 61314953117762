<p-dialog [(visible)]="display" [autoZIndex]="false" [closable]="closable" [closeOnEscape]="false" [draggable]="dynamic" [maximizable]="dynamic"
          [modal]="!dynamic" [resizable]="false" [transitionOptions]="'0ms'" appendTo="body" maximizeIcon="fas fa-fw fa-expand-arrows-alt"
          minimizeIcon="fas fa-fw fa-compress-arrows-alt" styleClass="{{styleClass}} one-dialog--{{size}} {{!hasContentPadding ? 'p-dialog--padding' : 'p-dialog--no-padding'}}">
  <ng-template pTemplate="header">
    <i *ngIf="icon" class="p-dialog-title__icon {{icon}}"></i>
    <span class="p-dialog-title__content">{{header}}</span>
    <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
  </ng-template>
  <ng-template *ngIf="lazy" pTemplate="contentTemplate">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </ng-template>
  <ng-content *ngIf="!lazy"></ng-content>
  <ng-template pTemplate="footer">
    <div *ngIf="dialogFooter" class="p-dialog-footer__content">
      <!-- custom footer (facet with name footer) -->
      <ng-container *ngIf="footer && display" [ngTemplateOutlet]="footer"></ng-container>
      <!-- default footer -->
      <ng-container *ngIf="!footer">
        <ui-button (clicked)="onCancel.emit(); close(); $event.cb()" icon="fas fa-fw fa-times" label="{{'Dialog.Cancel' | translate}}"
                   type="cancel"></ui-button>
        <ui-button (clicked)="save($event.cb)" [disable]="!readyToSave" icon="fas fa-fw fa-save" label="{{'Dialog.Save' | translate}}"
                   type="primary"></ui-button>
      </ng-container>
    </div>
  </ng-template>
</p-dialog>
