import {MaybePromise} from '@wspsoft/underscore';
import {SelectItem} from 'primeng/api';
import {Dropdown} from 'primeng/dropdown';


export interface CommandSelectItem extends SelectItem {
  color?: string;
  id?: string;
  tooltip?: string;

  command?(event?: any, dropdown?: Dropdown, enter?: boolean): MaybePromise<unknown>;
}
