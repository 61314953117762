import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Host,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild
} from '@angular/core';
import {ControlContainer, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Attachment, AttachmentUtil, KolibriEntity} from '@wspsoft/frontend-backend-common';
import {Message, MessageService} from 'primeng/api';
import {FileUpload} from 'primeng/fileupload';
import {Subscription} from 'rxjs';
import * as uuidv4 from 'uuid-random';
import {EntityService, EntityServiceFactory, FileService} from '../../../../../api';
import {environment} from '../../../../../config/environments/environment';

import {KolibriEntityConverterService} from '../../converter/kolibri-entity-converter.service';
import {CustomInput} from '../custom-input';

@Component({
  selector: 'ui-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FileUploadComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => FileUploadComponent),
    multi: true,
  }, {
    provide: KolibriEntityConverterService
  }],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: (container: ControlContainer) => container,
    deps: [[new Optional(), new Host(), new SkipSelf(), ControlContainer]],
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadComponent extends CustomInput<Attachment> implements OnInit, OnDestroy {
  public environment: typeof environment = environment;
  @Input()
  public renderInputGroup: boolean = false;
  @Input()
  public fileType: string;
  @Input()
  public tooltip: string = 'FileUpload.Label';
  @Input()
  public dropContainer: ElementRef<HTMLElement>;
  @Input()
  public parentEntityId: string;
  @Input()
  public publik: boolean;
  @Input()
  public imageCropper: boolean;
  @Input()
  public delayedSave: boolean;
  @Input()
  public temporary: boolean = false;
  @Input()
  public chooseIcon: string = 'pi pi-plus';
  @Input()
  public maxImageWidth: number;
  @Input()
  public maxImageHeight: number;
  @Input()
  public maxSize: number;
  @Input()
  public formId: string;
  @Output()
  public upload: EventEmitter<Attachment | Attachment[]> = new EventEmitter();
  @Output()
  public remove: EventEmitter<Attachment | Attachment[]> = new EventEmitter();
  @Output()
  public delayedAction: EventEmitter<(record: KolibriEntity) => Promise<any>> = new EventEmitter();
  public showCropper: boolean = false;
  public originalFile: File;
  public croppedFile: File;
  protected service: EntityService<Attachment>;
  @ViewChild(FileUpload, {static: false})
  private fileUpload: FileUpload;
  private subscription: Subscription;

  public constructor(private entityConverter: KolibriEntityConverterService, private element: ElementRef,
                     public translate: TranslateService, cdr: ChangeDetectorRef, private messageService: MessageService,
                     private serviceFactory: EntityServiceFactory, private fileService: FileService) {
    super(cdr);
    entityConverter.entityNameOrId = 'Attachment';
    this.converter = entityConverter;
    this.service = serviceFactory.getService('Attachment');
  }

  public ngOnInit(): void {
    this.dropContainer ??= this.element;
    if (!this.multiple) {
      // grab the incoming file and fetch payload for image crapper
      this.subscription = this.convertChange.subscribe(async () => {
        if (this.value && this.imageCropper) {
          await this.setFile();
        }
      });
    }

    if (this.dropContainer) {
      this.initDropZone();
    }
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public async uploadFile($event: { files: File[] }): Promise<void> {
    const toSave = [];
    const attachment = await this.service.getNewEntity(this.formId);
    try {
      for (const file of $event.files) {
        if (file.size === 0) {
          this.messageService.add({
            detail: this.translate.instant('FileUpload.Empty'),
            summary: '',
            severity: 'error'
          });
          if (!this.multiple) {
            return;
          } else {
            continue;
          }
        }

        await AttachmentUtil.fileToAttachment(attachment, file);

        if (file.type.startsWith('image/')) {
          if (file.type.includes('bmp')) {
            attachment.payload = await this.fileService.convert(attachment.payload, {targetFormat: 'png'});
            attachment.mimeType = 'image/png';
            attachment.fileName = attachment.fileName.replace(/.bmp$/, '.png');
          }

          attachment.payload = await this.fileService.resize(attachment.payload,
            {height: this.maxImageHeight, width: this.maxImageWidth, fit: 'inside', withoutEnlargement: true});
        }

        const copy = attachment.copy();
        copy.id = uuidv4();
        copy.publik = this.publik;
        toSave.push(copy);
      }
      const saveFn = async (record: KolibriEntity): Promise<Attachment> => {
        for (const toSaveElement of toSave) {
          toSaveElement.entity = record;
        }
        if (this.multiple) {
          // users might be crazy and upload gigantic amount of data :(
          await this.service.updateEntity(toSave);
        } else {
          return toSave[0].update();
        }
      };

      // i.e when adding an attachment for an email, which shouldn't be saved to db
      if (!this.temporary) {
        if (this.delayedSave) {
          this.delayedAction.emit(saveFn);
        } else {
          await saveFn({id: this.parentEntityId});
        }
      }

      if (!this.multiple) {
        if (this.value) {
          const deleteFn = (): Promise<void> => this.service.deleteEntity(this.value.id, undefined, true);
          if (this.delayedSave) {
            this.delayedAction.emit(deleteFn);
          } else {
            await deleteFn();
          }
        }

        this.value = toSave[0];
        await this.setFile();
      }
      this.upload.emit(this.value || toSave);
    } finally {
      this.clear();
    }
  }

  public onRemove(attachment: Attachment): void {
    this.remove.emit(attachment);
  }

  public clear(): void {
    this.fileUpload.clear();
  }

  public cropFile(): Promise<void> {
    return this.uploadFile({files: [this.croppedFile]});
  }

  public deleteFile(cb: () => void): void {
    const idToDelete = this.value.id;
    const deleteFn = async (): Promise<void> => {
      await this.service.deleteEntity(idToDelete);
    };
    if (!this.multiple) {
      this.value = null;
      this.originalFile = null;
    }
    this.delayedAction.emit(deleteFn);
    // trigger file upload change notification
    this.upload.emit(this.value);
    cb();
  }

  public validate(): ValidationErrors {
    if (!this.multiple && !this.value && this.require) {
      return {
        required: true
      };
    }
  }

  /**
   * catch all messages from primeng and show them as growl
   */
  public showMessage(msgs: Message[]): void {
    for (const msg of msgs) {
      this.messageService.add({
        ...msg,
        detail: msg.summary,
        summary: ''
      });
    }
  }

  public maxFileSize(): number {
    // @ts-ignore
    return (Math.min(this.maxSize || 60000, window.attachmentMaxSize)) * 1000;
  }

  private async setFile(): Promise<void> {
    // prefer local data over s3 stuff
    const res = this.value.payload ? await fetch(`data:${this.value.mimeType};base64,${this.value.payload}`) : await fetch(
      `${environment.serverAdd}/files/${this.value.id}`);
    const blob = await res.blob();
    this.originalFile = new File([blob], this.value.fileName, {type: this.value.mimeType});
    this.cdr.detectChanges();
  }

  private initDropZone(): void {
    const nativeElement = this.dropContainer.nativeElement;
    nativeElement.ondrop = e => {
      nativeElement.classList.remove('one-helper--highlight');
      this.fileUpload.onDrop(e);
      // Prevent default behavior (Prevent file from being opened)
      e.preventDefault();
    };

    nativeElement.ondragover = e => {
      nativeElement.classList.add('one-helper--highlight');
      // Prevent default behavior (Prevent file from being opened)
      e.preventDefault();
    };
    nativeElement.ondragleave = () => {
      nativeElement.classList.remove('one-helper--highlight');
    };
  }

  public checkForSvg(): void {
    if (this.originalFile?.type === 'image/svg+xml') {
      this.messageService.add({
        detail: this.translate.instant('FileUpload.Svg'),
        summary: '',
        severity: 'warn'
      });

      this.showCropper = false;
    }
  }
}
