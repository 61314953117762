<ui-input-wrapper [(editMode)]="editMode" [disable]="disable" [filled]="nativeInput.filled" [helpMessage]="helpMessage" [label]="label" [linkify]="linkify"
                  [require]="require">
  <ui-primeng-custom-autocomplete #nativeInput (completeMethod)="onComplete($event)" (onForceClear)="clear();" (onSelect)="onSelect.emit($event)"
                                  (onUnselect)="onUnselect.emit($event)" [(ngModel)]="targetValue" [disabled]="disable" [dropdown]="dropdown"
                                  [fields]="displayFields" [forceSelection]="false" [inputStyle]="styleData" [multiple]="multiple" [required]="require"
                                  [size]="size" [suggestions]="suggestions" appendTo="body" emptyMessage="{{'AutoComplete.NoResults' | translate}}"
                                  field="representativeString" name="native">
    <ng-template let-entity pTemplate="selectedItem">
      <div (click)="openRecord(entity, $event)" class="one-helper--pointer">
        <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: entity}"></ng-container>
      </div>
    </ng-template>
    <ng-template let-entity pTemplate="item">
      <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: entity}"></ng-container>
    </ng-template>
  </ui-primeng-custom-autocomplete>
</ui-input-wrapper>

<ng-template #itemTemplate let-entity>
  <ui-field-translator [data]="entity" [showIcon]="false" field="representativeString" typeName="KolibriEntity"></ui-field-translator>
  <ng-container *ngIf="displayFields">
    <ui-field-translator *ngFor="let field of displayFields" [data]="entity" [field]="field"></ui-field-translator>
  </ng-container>
</ng-template>
