import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {DynamicContentSize} from '@wspsoft/frontend-backend-common';
import {Dialog} from 'primeng/dialog';
import * as uuidv4 from 'uuid-random';
import GlobalHotkeys from '../../../../GlobalHotkeys.json';
import {LayoutViewMode} from '../../../entities/layout-view-mode';
import {HotkeyService} from '../../../service/hotkey.service';

import {OneDialogService} from '../../../service/one-dialog.service';
import {ButtonHotkeyWeightOffset, UiUtil} from '../../../util/ui-util';

/**
 * wrapper component for a uniform p dialog across all apps
 */
@Component({
  selector: 'ui-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogComponent<T> implements OnInit, OnDestroy {
  @Input()
  public name: string;
  @Input()
  public id: string = uuidv4();
  @Input()
  public styleClass: string;
  @Input()
  public size: DynamicContentSize = DynamicContentSize.LARGE;
  @Input()
  public lazy: boolean = true;
  @Input()
  public dynamic: boolean = false;
  @Input()
  public icon: string;
  @Input()
  public visible: boolean = false;
  @Input()
  public hasContentPadding: boolean = false;
  @Input()
  public header: string;
  @Input()
  public dialogFooter: boolean;
  @Input()
  public onSave: () => Promise<boolean>;
  @Input()
  public closable: boolean = true;
  @Output()
  public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public onCancel: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public onShow: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public onHide: EventEmitter<void> = new EventEmitter<void>();
  // optional data passed to dialog when opened
  @Input()
  public data: T;
  @ContentChild('content', {static: true})
  public content: TemplateRef<any>;
  @ContentChild('header', {static: true})
  public headerTemplate: TemplateRef<any>;
  @ContentChild('footer', {static: true})
  public footer: TemplateRef<any>;
  public buttonHotkeyWeightOffset: ButtonHotkeyWeightOffset = ButtonHotkeyWeightOffset.System;
  public readyToSave: boolean = true;
  @ViewChild(Dialog, {static: true})
  private pDialog: Dialog;
  private shelvedHotkeys: boolean = false;

  public constructor(private dialogService: OneDialogService, protected cdr: ChangeDetectorRef, protected hotkeyService: HotkeyService) {
  }

  public get display(): boolean {
    return this.visible;
  }

  public set display(visible: boolean) {
    this.visible = visible;
    this.visibleChange.emit(visible);

    if (visible) {
      this.onShow.emit();
      if (!this.dynamic && !this.shelvedHotkeys) {
        this.hotkeyService.shelveHotkeys();
        this.shelvedHotkeys = true;
      }
      this.addDefaultHotkeys();
    } else {
      this.onHide.emit();
      this.removeDefaultHotkeys();
      if (!this.dynamic && this.shelvedHotkeys) {
        this.hotkeyService.unshelveHotkeys();
        this.shelvedHotkeys = false;
      }
    }
    this.cdr.detectChanges();
  }

  public ngOnInit(): void {
    // only register when called by name
    if (this.name) {
      this.dialogService.register(this);
    }
    if (this.visible) {
      this.display = true;
    }
  }

  public ngOnDestroy(): void {
    // only register when called by name
    if (this.name) {
      this.dialogService.unregister(this);
    }
    if (this.shelvedHotkeys) {
      this.hotkeyService.unshelveHotkeys();
      this.shelvedHotkeys = false;
    }
    if (this.visible) {
      this.removeDefaultHotkeys();
    }
  }

  public async save(cb: () => void = () => {
  }): Promise<void> {
    let close = true;

    // only when function was given
    if (this.onSave) {
      close = await this.onSave();
    }

    // close if save function was ok
    if (close) {
      this.close();
    }

    cb();
  }

  public close(): void {
    this.display = false;
  }

  public show(data?: T): void {
    this.data = data;
    this.display = true;
  }

  public toggle(data?: T): boolean {
    this.data = data;
    this.display = !this.display;
    return this.display;
  }

  /**
   * Adds default hotkeys for the dialog
   * E.g. Close on Escape
   */
  protected addDefaultHotkeys(): void {
    if (!this.dynamic && this.closable) {
      this.hotkeyService.addBinding(GlobalHotkeys.DialogClose.id, () => {
        this.close();
      }, GlobalHotkeys.DialogClose.keys, UiUtil.getHotkeyWeightBasedOnViewMode(LayoutViewMode.DIALOG) + this.buttonHotkeyWeightOffset);
    }
  }

  /**
   * Removes default hotkeys from the dialog
   * E.g. Close on Escape
   */
  private removeDefaultHotkeys(): void {
    if (!this.dynamic && this.closable) {
      this.hotkeyService.removeBinding(GlobalHotkeys.DialogClose.id);
    }
  }
}
