import {FlowElement} from '../model/database/flow-element';

export abstract class LowCodeUtil {
  public static readonly FLOWLOOP_FLOWSWITCH: string[] = ['FlowLoop', 'FlowSwitch'];

  public static iterateFlowElement(flowElement: FlowElement, cb: (nextElements: FlowElement[]) => void): void {
    if (this.FLOWLOOP_FLOWSWITCH.includes(flowElement.entityClass) && flowElement.caseData) {
      for (const caseData of Object.values(flowElement.caseData)) {
        cb(caseData);
      }
    } else {
      for (const value of Object.values(flowElement)) {
        if (Array.isArray(value)) {
          cb(value);
        }
      }
    }
  }
}