import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Choice, ChoiceValue} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {ModelService, ModelTranslationService} from '../../../../../../api';
import {MultiSelect} from '../multi-select';

@Component({
  selector: 'ui-choice-multiselect',
  templateUrl: './choice-multi-select-input.component.html',
  styleUrls: ['./choice-multi-select-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ChoiceMultiSelectInputComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChoiceMultiSelectInputComponent extends MultiSelect<ChoiceValue[]> {
  @Input()
  public choiceId: string;

  public constructor(private modelTranslationService: ModelTranslationService, private modelService: ModelService, cdr: ChangeDetectorRef) {
    super(cdr);
  }

  protected setupOptions(): void {
    const choiceMeta: Choice = this.modelService.getChoice(this.choiceId);
    for (const value of choiceMeta.values) {
      value.label = this.modelTranslationService.translateChoiceValue(value, this.choiceId);
    }
    this.options = _.sortBy(choiceMeta.values, ['order', 'label']).map(value => ({
      value,
      label: value.label,
      icon: value.icon,
      title: value.label
    }));
  }
}
